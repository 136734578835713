<template>
  <div class="standard-data">
    <div v-if="hasLocations" class="address">
      <p class="has-text-weight-bold">
        LOCATION
      </p>
      <p>{{ location.name }}</p>
      <p>{{ location.address }}</p>
      <p>
        {{ location.city }},
        {{ location.state }}
        {{ location.zip }}
      </p>
    </div>

    <p v-if="hasLocations && location.phone" class="is-together result-phone">
      <span class="icon"><fa icon="phone" /></span>
      <a :href="`tel:${location.phone}`">
        {{ location.phone }}
      </a>
    </p>

    <div v-if="record['web-profile']" class="is-together">
      <fa icon="globe" />
      <a :href="format('url', record['web-profile'])" target="_blank">
        {{ record['web-profile'] }}
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import formatField from '@/mixins/fields/formatField';

export default {
  mixins: [formatField],
  props: {
    record: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      fields: 'fields/get',
    }),
    hasLocations() {
      return this.record?.locations?.[0]?.address;
    },
    location() {
      return this.record.locations[0];
    },
  },
  methods: {
    mapFields(field) {
      return [
        {
          value: this.formatField(field.type, this.record[field.key]),
        },
      ];
    },
    format(type, field) {
      return this.formatField(type, field);
    },
  },
};
</script>
