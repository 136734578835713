<template>
  <div>
    <div v-for="facet in validFacets" :key="facet.key">
      <h2 class="has-text-weight-bold">
        {{ facet.name.toUpperCase() }}
      </h2>
      <div class="is-facet">
        <StringFacet v-if="facet.type === 'string'" :facet="facet" :record="record" />
        <ArrayFacet v-if="facet.type === 'array'" :facet="facet" :record="record" />
        <TreeFacet v-if="facet.type === 'tree'" :facet="facet" :record="record" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import StringFacet from '@/components/Record/Facets/StringFacet.vue';
import ArrayFacet from '@/components/Record/Facets/ArrayFacet.vue';
import TreeFacet from '@/components/Record/Facets/TreeFacet.vue';

export default {
  props: {
    record: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      facets: 'fields/facets',
    }),
    validFacets() {
      return this.facets
        .filter((facet) => this.record[facet.key])
        .filter((facet) => {
          const hasKeys = this.record[facet.key].length > 0
            || Object.keys(this.record[facet.key]).length > 0;

          let hasTreeKeys = true;
          if (facet.type === 'tree') {
            hasTreeKeys = Object.keys(this.record[facet.key]?.lvl0 ?? {}).length > 0;
          }

          return hasKeys && hasTreeKeys;
        })
        .filter((facet) => this.record.sponsor || facet.free);
    },
  },
  components: {
    StringFacet,
    ArrayFacet,
    TreeFacet,
  },
};
</script>
