<template>
  <div class="standard-data">
    <p class="has-text-weight-bold">
      <u>RANKED LISTS</u>
    </p>
    <table class="table">
      <thead>
        <tr>
          <th>Rank</th>
          <th>List</th>
          <th>Field</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="list in lists" :key="list.key">
          <td>{{ list.rank }}</td>
          <td>{{ list.key }}</td>
          <td>{{ list.field }}</td>
          <td>{{ format(list) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import formatField from '@/mixins/fields/formatField';

export default {
  mixins: [formatField],
  props: {
    lists: {
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      fields: 'fields/get',
    }),
  },
  methods: {
    sort(lists) {
      return lists.sort((a, b) => a.rank - b.rank);
    },
    format(list) {
      const { type } = this.fields.filter(
        (field) => field.name === list.field,
      )[0];

      return this.formatField(type, list.value);
    },
  },
};
</script>
