<template>
  <div>
    <div v-for="link in validLinks" :key="link.key">
      <router-link :to="link">
        View all {{ link.name }}
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    record: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      links: 'fields/links',
    }),
    validLinks() {
      return this.links
        .filter((link) => this.record.sponsor || link.free)
        .map((link) => `/${this.directory(link)}?${link.crossdirectory_facet}=${
          this.record.name}`);
    },
  },
  methods: {
    directory(field) {
      if (field.crossdirectory) {
        return field.crossdirectory;
      }

      return this.$route.params.directory;
    },
  },
};
</script>
