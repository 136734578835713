<template>
  <div
    class="tag is-small is-sponsored-badge has-background-primary
      has-text-white has-text-weight-semibold"
  >
    {{ badge }}
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      currentDirectory: 'directory/current',
    }),
    badge() {
      return this.currentDirectory.badge_text;
    },
  },
};
</script>
